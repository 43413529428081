#page-shipping-select {
  width: 100vw;
  height: 100vh;
}

#page-shipping-select .progress {
  width: 90%;
  display: block;
  margin: 3.6rem auto;
}

#page-shipping-select .shipping-select-content form {
  display: flex;
  flex-direction: column;
}

#page-shipping-select .send-method div {
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.4rem;
  align-items: center;
  justify-content: space-between;
}

#page-shipping-select .send-method div + div {
  margin-top: 2.4rem;
}

#page-shipping-select .form-group {
  max-width: 74rem;
  margin: 1.6rem;
  padding: 1.6rem;
  overflow: hidden;
  background: var(--color-cinza-fundo);
  border: 1px solid var(--color-azul-de-fundo);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

#page-shipping-select .form-group fieldset {
  min-inline-size: auto;
  border: 0;
  margin: 2rem 0.8rem 2rem 0;
}

#page-shipping-select .form-group legend {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 1.2rem;
}

#page-shipping-select .form-group legend h3 {
  font-size: 1.8rem;
  text-align: start;
}

#page-shipping-select .form-group legend svg{
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.6rem;
}

#page-shipping-select .form-group strong {
  font: 700 2rem Arima Madurai;
  color: var(--color-rosa-claro);
}

#page-shipping-select .form-group h3 {
  text-align: center;
}

#page-shipping-select .form-group p {
  font-family: Archivo;
}

#page-shipping-select .form-group p em {
  font: 700 1.6rem Archivo;
  color: var(--color-rosa-claro);
}

#page-shipping-select .form-group .box {
  background: white;
  width: 100%;
  margin: 0;
  padding: 1.6rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
}

#page-shipping-select .form-group .box p {
  margin: 0.4rem 0 0;
  text-align: left;
}

#page-shipping-select .right-bar {
  max-width: 48rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
}

#page-shipping-select .form-group .link,
#page-shipping-select .right-bar > button {
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  font: 700 1.6rem Archivo;
  cursor: pointer;
  transition: color 0.2s;
}

#page-shipping-select .right-bar > button {
  margin: 4rem auto;
  color: var(--color-amarelo-escuro);
}

#page-shipping-select .form-group .link {
  margin: 1.6rem auto;
  color: var(--color-rosa-escuro);
}

#page-shipping-select .link svg {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
}

#page-shipping-select .bottom-bar  {
  width: 90vw;
  background: var(--color-cinza-fundo);
  padding: 1.6rem;
  margin: 0 auto;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
}

#page-shipping-select .bottom-bar h3 {
  font-size: 1.8rem;
  text-align: center;
  padding: 2.4rem;
}
#page-shipping-select .bottom-bar strong {
  display: block;
  max-width: 64rem;
  text-align: center;
  align-self: center;
}

#page-shipping-select .bottom-bar strong em {
  font: 700 1.6rem Archivo;
  color: var(--color-pagseguro);
}

#page-shipping-select .bottom-bar img {
  width: 50vw;
  max-width: 35rem;
  align-self: center;
  padding: 1.6rem;
}

@media (min-width: 700px) {
  #page-shipping-select .progress {
    width: 60rem;
  }

  #page-shipping-select .shipping-select-content form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 0 4rem;
  }

}

@media (min-width: 1100px) {
  #page-shipping-select .form-group .box-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

}
